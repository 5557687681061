
import "./Portfolio.scss";
import React from "react";
import { useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/all";
import { gsap } from "gsap";
import  Heading  from "./Heading";
import Card from "./Card";

import promptShareImage from "../assets/images/promptShareImage.png";
import lightSaasLandingPage from "../assets/images/light-saas-landing-page.png";
import aiStartupLandingPage from "../assets/images/ai-startup-landing-page.png";
// import CheckCircleIcon from "../assets/icons/check-circle.svg";
// import ArrowUpRightIcon from "../assets/icons/arrow-up-right.svg";
import grainImage from "../assets/images/grain.jpg";
import iphoneLandingImage from "../assets/images/iphoneLandingImage.png";
import books from "../assets/images/books.png";
import tiff from "../assets/images/tiff.png";
import portfolio from "../assets/images/portfolio.png";
import friends from "../assets/images/friends.png";

import { Reveal } from "../utils/Reveal";

import {FiExternalLink} from "react-icons/fi";
import {FaGithub} from "react-icons/fa";



interface Project {
  company: string;
  year: string;
  title: string;
  results: { title: string }[];
  tech: string[]; 
  link: string;
  image: string;
  code: string;
  projectLink: string;
}

const portfolioProjects: Project[] = [

  {
    company: "Portfolio V2",
    year: "2024",
    title: "Portfolio Design and Development",
    results: [
      { title: "Respsonsive Design" },
      { title: "Designed with Figma for a seamless user experience" },
    ],
    tech: ["Built with", "Typescript", "SCSS", "React", "Tailwind CSS", "JavaScript"],
    link: "",
    image: aiStartupLandingPage,
    code: "",
    projectLink: "https://www.jamiefudge.com/",
  },
  {
    company: "Alien Finder App",
    year: "2024",
    title: "TypeScript App",
    results: [
      { title: "Integrated the REST Countries API to fetch data" },
    ],
    tech: ["Built with", "TypeScript", "Next.js", "JavaScript", "SCSS", "Tailwind CSS", "Framer Motion"],
    link: "",
    image: promptShareImage,
    code: "https://github.com/jfudge/countries-api",
    projectLink: "https://main--alien-app.netlify.app/",
  },
  {
    company: "Book App",
    year: "2024",
    title: "React App",
    results: [
      { title: "A web app for storing your favourite books." },
    ],
    tech: ["Built with", "React", "JavaScript", "Express", "Nodejs", "Mongoose", "Mongodb", "HTML5", "CSS"],
    link: "",
    image: books,
    code: "https://github.com/jfudge/books-app",
    projectLink: "",
  },
  {
    company: "TIFF App",
    year: "2024",
    title: "React App",
    results: [
      { title: "View the latest movies with interesting facts." },
    ],
    tech: ["Built with", "React", "JavaScript", "HTML5", "CSS"],
    link: "",
    image: tiff,
    code: "https://github.com/jfudge/react-tmdb-movies.git",
    projectLink: "https://movies-b2e50.firebaseapp.com/",
  },
  {
    company: "Portfolio V1",
    year: "2024",
    title: "",
    results: [
      { title: "Design and Development" },
    ],
    tech: ["Built with", "HTML5", "CSS"],
    link: "",
    image: portfolio,
    code: "https://github.com/jfudge/portfolio-1.0",
    projectLink: "https://v1.jamiefudge.com/",
  },
  {
    company: "Friends App",
    year: "2024",
    title: "Python App",
    results: [
      { title: "Create and add new Friends to the list." },
    ],
    tech: ["Built with", "Python", "Flask"],
    link: "",
    image: friends,
    code: "https://github.com/jfudge/flask-friends-crud",
    projectLink: "",
  },
  {
    company: "Respsonsive Email",
    year: "2024",
    title: "Respsonsive Email Design & Development",
    results: [
      { title: "Respsonsive" },
    ],
    tech: ["Built with", "HTML", "CSS"],
    link: "",
    image: iphoneLandingImage,
    code: "https://github.com/jfudge/white-claw-email",
    projectLink: "https://em.jamiefudge.com/",
  },
  {
    company: "CAAT Pension",
    year: "2024",
    title: "Full Stack Development",
    results: [
      { title: "Increased mobile traffic by 35%" },
      { title: "Expanded customer reach" },
      { title: "Enhanced user experience by 40%" },
      { title: "Improved accessibility" },
    ],
    tech: ["Bootstrap", "HTML5", "CSS", "JavaScript", "SQL", "C#", ".NET"],
    link: "",
    image: lightSaasLandingPage,
    code: "",
    projectLink: "https://www.caatpension.ca/",
  },
];

const Portfolio: React.FC = () => {
  const portfolioSection = useRef(null);
  const heading = useRef(null);
  const body = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: portfolioSection.current,
      start: "top 400px",
      animation: gsap
      .timeline()
      .to(
        heading.current,
        { opacity: 1, y: 0, ease: "power4.out", duration: 1.25 },
        0
      )
      .to(
        body.current,
        { opacity: 1, y: 0, ease: "power4.out", duration: 1.25 },
        0.2
      ),
    toggleActions: "play none none none",
  });
  ScrollTrigger.refresh();
  }, [portfolioSection]);

  return (
   
    <section ref={portfolioSection} id="portfolio" className="section bg-tertiary">
      <><div className="container mx-auto">
        <Heading title="Recent" />

        <div className="flex flex-col mt-5 md:mt-20 gap-20">
          {portfolioProjects.map((project, projectIndex) => (
            <Card
              key={project.title}
              className="bg-slate rounded-3xl z-0 after:z-10 overflow-hidden after:content-[''] after:absolute after:inset-0 after:outline-2 after:outline after:-outline-offset-2 after:rounded-3xl after:outline-white/20 px-8 pt-8 md:pt-12 md:px-10 lg:pt-16 lg:px-20 after:pointer-events-none sticky"
              style={{
                top: `calc(154px + ${projectIndex * 10}px)`,
              }}>
              <div
                className="absolute inset-0 -z-10 opacity-5"
                style={{
                  backgroundImage: `url(${grainImage})`,
                }}
              ></div>
              <div className="lg:grid lg:grid-cols-2 lg:gap-16">
                <div className="lg:pb-16">
                  <div className="aqua gap-2 font-bold uppercase tracking-widest text-sm">
                  <Reveal><span>{project.company}</span></Reveal>
                  </div>
                  <Reveal><h4 className="bg-gradient-to-r from-emerald-300 to-sky-400 inline-flex  bg-clip-text text-transparent folio-head text-2xl mt-2 md:text-4xl md:mt-5">
                    {project.title}
                  </h4></Reveal>
                  {/* <hr className="border-t-2 border-white/5 mt-4 md:mt-5" /> */} 
                  <Reveal>
                    <ul className="flex flex-col gap-4 mt-4 md:mt-4">
                      {project.results.map((result, idx) => (
                        <li
                          key={idx}
                          className={"white"}>
                          {result.title}
                        </li>
                      ))}
                    </ul>
                  </Reveal>
                  <Reveal>
                    <ul className="flex flex-wrap gap-2 mt-4">
                      {project.tech.map((tech, idx) => (
                        <span key={idx} className="green uppercase mr-2">
                          {tech}
                        </span>
                      ))}
                    </ul>
                  </Reveal>
                  <br></br><br></br>
                  <Reveal>
                    <div>
                      {project.projectLink && (
                        <a className="inline-block" href={project.projectLink} target="_blank" rel="noopener noreferrer">
                          <FiExternalLink className="green w-6 h-6 mr-5" />
                        </a>
                      )}
                      {project.code && (
                        <a className="inline-block" href={project.code} target="_blank" rel="noopener noreferrer">
                          <FaGithub className="green w-6 h-6" />
                        </a>
                      )}
                    </div>
                  </Reveal>

                </div>
                <div className="relative">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="mt-8 -mb-4 md:-mb-0 lg:mt-0 lg:absolute lg:h-full lg:w-auto lg:max-w-none"
                  />
                </div>
              </div>
            </Card>
          ))}
        </div>

      </div></>
    </section>
  );
};

export default Portfolio;

