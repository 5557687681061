import React from 'react'
import "./Services.scss";
import { Tilt } from "react-tilt";
import { useState } from 'react';
import { motion } from 'framer-motion';
import { exploreServices } from "../../data";
import ServiceCard from "../Services/ServicesCard";
import { GiSurroundedEye }from "react-icons/gi";

const Services = () => {

  const [active, setActive] = useState('service-2');
  const staggerContainer = {
    hidden: {},
    show: {
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  return (
    <section id="services" className="bg-tertiary sm:p-16 xs:p-8 px-6 py-12 d-none">
      <div className="container mx-auto">
        <div className="flex flex-col items-center text-center">
          <h2 className="white s-title d-inline-block">
            SERVICES
            <Tilt className="d-inline-block xs:w-[280px] ps-3">
              <GiSurroundedEye className="d-inline-block" />
            </Tilt>
          </h2>
        </div>
        <br></br>
        <>
          <motion.div variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: false, amount: 0.25 }}
            className={`mx-auto flex flex-col`}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {exploreServices.map((service, index) =>
                  <ServiceCard key={index}
                    {...service}
                    index={index}
                    active={active}
                    handleClick={setActive} />
              )}
            </div>
          </motion.div>
        </>
      </div>
    </section>

  );
}

export default Services