import React, {
    ComponentPropsWithRef,
    ComponentPropsWithoutRef,
    PropsWithChildren,
  } from "react";
  import grainImage from "../assets/images/grain.jpg";
  import { twMerge } from "tailwind-merge";
  
  const Card = ({
    className,
    children,
    ...other
  }: ComponentPropsWithoutRef<"div">) => {
    return (
      <div
        className={twMerge(
          "bg-secondary rounded-3xl relative z-0 after:z-10 overflow-hidden after:content-[''] after:absolute after:inset-0 after:outline-2 after:outline after:-outline-offset-2 after:rounded-3xl after:outline-white/20 after:pointer-events-none",
          className
        )}
        {...other}
      >
        <div
          className="absolute inset-0 -z-10 opacity-5"
          style={{
            backgroundImage: `url(${grainImage})`,
          }}
        ></div>
        {children}
      </div>
    );
  };
  
  export default Card;
  